import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledImageWithDescription = styled.section``

const ImageWithDescriptionImage = styled(Image)`
  @media (min-width: 992px) {
    max-width: 700px;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    max-width: 650px;
    width: 100%;

    & img {
      object-fit: contain !important;
    }
  }
`

const ImageWithDescriptionDescription = styled(ParseContent)`
  & a {
    text-decoration: underline;
  }

  & .button a {
    text-decoration: none;
  }
`

interface ImageWithDescriptionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ImageWithDescription
}

const ImageWithDescription: React.FC<ImageWithDescriptionProps> = ({
  fields,
}) => (
  <StyledImageWithDescription className="my-1 my-lg-5">
    <div className="container py-5">
      <div className="row">
        <div className="col-md-7">
          <ImageWithDescriptionImage
            className="mx-auto"
            image={fields.image}
            alt="AddComm"
          />
        </div>
        <div className="col-md-5 pt-5 pb-lg-5 pl-lg-5">
          <ImageWithDescriptionDescription content={fields.description} />
        </div>
      </div>
    </div>
  </StyledImageWithDescription>
)

export default ImageWithDescription
